<template>
  <div class="fitment-header">
    <van-nav-bar
      title="店铺装修"
      left-arrow
      @click-left="$router.back(-1)"
      fixed
      placeholder
      right-text="完成"
      @click-right="complete"
    />

    <div class="preview" v-if="type === '1'">
      <header-small :value="content"></header-small>
    </div>
    <div class="preview" v-else-if="type === '2'">
      <header-big :value="content"></header-big>
    </div>

    <div>
      <van-cell-group>
        <van-cell title="店招">
          <template #right-icon>
            <div class="right-inner" @click="upload('sign')">
              <van-image
                width="196"
                height="40"
                :src="content.signUrl ? content.signUrl : sign"
              />
              <van-icon name="arrow" color="#ccc" />
            </div>
          </template>
        </van-cell>

        <van-cell title="店铺logo">
          <template #right-icon>
            <div class="right-inner" @click="upload('logo')">
              <van-image
                width="40"
                height="40"
                :src="content.logoUrl ? content.logoUrl : logo"
              />
              <van-icon name="arrow" color="#ccc" />
            </div>
          </template>
        </van-cell>

        <van-field
          v-model="content.storeName"
          label="店铺名称"
          placeholder="请输入"
          input-align="right"
        />

        <van-field
          v-model="content.storeDescribe"
          label="店铺简介"
          placeholder="请输入"
          input-align="right"
          maxlength="15"
        />
      </van-cell-group>

      <!-- 文件上传 -->
      <input
        type="file"
        accept="image/*"
        ref="fileInput"
        @change="fileChange"
        style="display: none"
      />
    </div>
  </div>
</template>

<script>
import headerSmall from "@/components/fitment-box/header-small";
import headerBig from "@/components/fitment-box/header-big";

import { upload } from "@/api/upload";
export default {
  name: "fitmentHeader",
  components: { headerSmall, headerBig },
  data() {
    return {
      type: null,

      sign: require("@/assets/fitment/default-bgc.png"),
      logo: require("@/assets/fitment/default.png"),

      uploadType: null,

      content: {
        signUrl: null,
        logoUrl: null,
        storeName: null,
        storeDescribe: null,
      },
    };
  },
  created() {
    this.type = this.$route.query.type;
    if (this.$store.state.fitment.headerContent.storeName) {
      this.content = JSON.parse(JSON.stringify(this.$store.state.fitment.headerContent))
    }
  },
  methods: {
    // input file 点击事件
    upload(type) {
      this.$refs.fileInput.click();
      this.uploadType = type;
    },
    // 文件上传
    fileChange(e) {
      let formData = new window.FormData();
      formData.append("file", e.target.files[0]);
      formData.append("fileType", "image/png");
      formData.append("dir", "material");

      upload(formData).then((res) => {
        console.log(res);
        this.uploadType === "sign"
          ? (this.content.signUrl = res.data.data)
          : (this.content.logoUrl = res.data.data);
      });
    },
    // 右上角完成
    complete() {
      if (!this.content.storeName) {
        this.$toast("请输入店铺名称");
      } else if (!this.content.storeDescribe) {
        this.$toast("请输入店铺简介");
      } else {
        this.$store.commit("setStoreType", this.type);
        this.$store.commit("setHeaderContent", this.content);
        this.$router.go(-2);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.fitment-header {
  background-color: #f6f6f6;
  .preview {
    background-color: #555;
    padding: 4vw 0;
    display: flex;
    justify-content: center;
  }
  /deep/ .van-cell__title {
    display: flex;
    align-items: center;
    color: #666;
  }
  .right-inner {
    display: flex;
    align-items: center;
    .van-icon {
      margin-left: 10px;
    }
  }
}
</style>